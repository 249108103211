import React from 'react';
import {Button, Card, Stack, ToastContainer} from "react-bootstrap";

interface RefreshButtonProps {
  lastUpdated: Date | null;
  handleRefresh: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
}

const RefreshButtonCard: React.FC<RefreshButtonProps> = ({lastUpdated, handleRefresh, disabled, className}) => {
  return (
    <ToastContainer position='bottom-start' className={`p-2 pe-auto pb-4 ${className}`}>
      <Card className={`text-center d-inline-flex p-2`}>
        <Card.Body className="m-0 p-0">
          <Stack> {/*stack is required to hide weird space above the button*/}
            <Button onClick={handleRefresh} variant='secondary' className="w-100" disabled={disabled}>Refresh</Button>
            {lastUpdated &&
                <Card.Text className="mb-0" style={{fontSize: '0.75rem'}}>
                  Updated: {lastUpdated.toLocaleDateString(undefined, {
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
                </Card.Text>
            }
          </Stack>
        </Card.Body>
      </Card>
    </ToastContainer>
  );
};


export default RefreshButtonCard;
