import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {IShipment} from '../data/data-types'
import {isOverdue, getFriendlyDate} from "../dateHelpers";
import {Accordion, Alert, Button, Col, Container, Row, Image} from "react-bootstrap";
import Markdown from 'markdown-to-jsx';
import {BadgesColumn} from "./badgesColumn";

interface ShipmentsAccordionProps {
  shipments: IShipment[];
}

export const ShipmentsAccordion = ({
                                     shipments
                                   }: ShipmentsAccordionProps) => {
  // Sort the shipments by dueDate, empty dueDates at the top
  const sortedShipments = [...shipments].sort((a, b) => {
    if (!a.dueDate) return -1;
    if (!b.dueDate) return 1;
    return a.dueDate.localeCompare(b.dueDate);
  });

  const activeKeys = shipments.map((_, i) => i.toString());

  //todo show PDF in modal when clicked

  return (
    <Accordion defaultActiveKey={activeKeys} alwaysOpen flush>
      {sortedShipments.map((shipment, i) => (
        <Accordion.Item eventKey={i.toString()}>
          <Accordion.Header>
            <Col>
              <div className="fw-bold fs-6 me-2">{shipment.description ?? shipment.saleId}</div>
              <div className='me-2'>
                {shipment.description && <div className='text-secondary me-2 text-nowrap'>{shipment.saleId}</div>}
                <span
                  className={`${isOverdue(shipment.dueDate) && shipment.holdForPayment && 'text-decoration-line-through'}`}>Due: {getFriendlyDate(shipment.dueDate)}</span>
              </div>
            </Col>
            <BadgesColumn className='pe-2'
                          preProduction={!shipment.toProduction}
                          overdue={isOverdue(shipment.dueDate) && !shipment.holdForPayment}
                          hold={shipment.holdForPayment}
                          installedByPWI={shipment.installedByPWI}></BadgesColumn>
          </Accordion.Header>
          <Accordion.Body>
            {shipment.holdForPayment &&
                <Alert key='holdForPayment' variant='danger' className='fw-bold'>⛔ Hold for Payment</Alert>}
            <dl className="">
              <dt>Sales Channel</dt>
              <dd className="ms-1">{shipment.salesChannel}</dd>
              <dt>Status</dt>
              <dd className="ms-1">{shipment.status}</dd>
              <dt>Special Instructions</dt>
              <dd className="ms-1">{shipment.specialInstructions ?
                <Markdown>{shipment.specialInstructions}</Markdown> : '-'}</dd>
              {shipment.notes && <>
                <dt className="">Notes</dt>
                <dd>{shipment.notes}</dd>
              </>}
              <dt>Shipping List</dt>
              <dd className={shipment.packingSlips && shipment.packingSlips.length !== 0 ? '' : 'ms-1'}>
                {shipment.packingSlips && shipment.packingSlips.length !== 0 ?
                  <Container fluid>
                    <Row xs={2}>
                      {shipment.packingSlips.map((packingSlip) => (
                        <Col className='p-0'>
                          <Button variant='outline-light' href={packingSlip.url} className='p-1 border-0'
                                  target='_blank'>
                            <Image src={packingSlip.thumbnails?.large.url} thumbnail/>
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                  : '(no packing slips)'}
              </dd>
            </dl>
            <Button variant="outline-primary" size="sm" href={shipment.portalLink} target='_blank'>View in
              Portal</Button>
          </Accordion.Body>
        </Accordion.Item>
      ))
      }
    </Accordion>

  );
}
