import React from 'react';
import {Modal, Button} from "react-bootstrap";

interface RefreshModalProps {
  show: boolean;
  onHide: () => void;
  onRefresh: () => void;
}

const RefreshModal: React.FC<RefreshModalProps> = ({show, onHide, onRefresh}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Refresh Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The packing slip document links are likely broken. Please click refresh to fix.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onRefresh}>
          Refresh
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RefreshModal;
