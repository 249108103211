import {Button, Card, ToastContainer} from "react-bootstrap";
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

interface SignOutCardProps {
  onSignOut: () => void;
  className?: string;
}

export const SignOutCard = ({onSignOut, className}: SignOutCardProps) => {

  return (
    <ToastContainer position='top-end' className={`p-2 pe-auto ${className}`}>
      <Card className=''>
        <Card.Body className='p-1'>
          <Button variant="danger" className='text-nowrap ms-auto me-auto' onClick={onSignOut}>Sign Out</Button>
        </Card.Body>
      </Card>
    </ToastContainer>
  )
}