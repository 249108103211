import {SalesChannel} from "./data/data-types";
import IDataFilters from "./data/iDataFilters";

export const DEFAULT_SALES_CHANNELS : SalesChannel[] = ["PWI", "Hoist Zone", "Stair Zone", "Structural Deck"];

export const UNKNOWN_LOCATION_NAME = '(unknown building)';

export const DEFAULT_FILTERS: Readonly<IDataFilters> = {
  onOrAfter: undefined,
  onOrBefore: undefined,
  salesChannels: DEFAULT_SALES_CHANNELS,
  hideNotToProduction: false,
  showTypes: "all",
};