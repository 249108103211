import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwAtOTFJySkTisfT0yciBCr5-UMwiprnI",
  authDomain: "pwi-apps.firebaseapp.com",
  databaseURL: "https://pwi-apps-default-rtdb.firebaseio.com",
  projectId: "pwi-apps",
  storageBucket: "pwi-apps.appspot.com",
  messagingSenderId: "277637891266",
  appId: "1:277637891266:web:749c956ace905f11ac675b",
  measurementId: "G-N8DB9C2GC2"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);