// locationDetail.tsx
import React from 'react';
import {ILocation} from "../data/data-types";
import {ShipmentsAccordion} from "./shipmentsAccordion";
import {Alert, Button, Offcanvas, OffcanvasBody, OffcanvasHeader, OffcanvasTitle} from "react-bootstrap";
import {UNKNOWN_LOCATION_NAME} from "../constants";

interface LocationDetailProps {
  location: ILocation;
  show: boolean;
  onHide: () => void;
  onExited: () => void;
  className?: string;
  placement?: 'start' | 'end' | 'top' | 'bottom';
}

export const LocationDetail = ({location, show, onHide, onExited, placement, className}: LocationDetailProps) => {

  const hasAddress = location.address && location.address.street?.length > 1;

  return (
    <Offcanvas show={show} onHide={onHide} onExited={onExited} scroll={true} backdrop={false} placement={placement}
               className={`${className} ${placement === 'bottom' && 'h-50'}`}>
      <OffcanvasHeader closeButton className=''>
        <OffcanvasTitle className='fs-4'>{location.name}</OffcanvasTitle>
      </OffcanvasHeader>
      <OffcanvasBody className='px-0 pt-0'>
        <div className="px-3">
          <dl className=''>
            <dt className="">Address</dt>
            {hasAddress ? <dd className=''>
                <Button variant='link' className='text-start px-1'
                        href={`https://www.google.com/maps/dir/?api=1&travelmode=driving${location.coordinates ? `&destination=${location.coordinates.lat}%2C${location.coordinates.lng}` : ''}`}
                        target='_blank'>
                  <p className='mb-0'>{location.address?.street}</p>
                  <p className='mb-0'>{location.address?.city}, {location.address?.state} {location.address?.zip}</p>
                </Button>
              </dd>
              : <dd>No address specified</dd>}
            {location.coordinates ?
              <Button size='sm' className='mb-2 ms-1'
                      variant='outline-primary'
                      href={location.link} target='_blank'>View in Portal</Button>
              : <Alert key='holdForPayment' variant='danger' className='fw-bold mt-1 mb-1 py-2'>
                <div className=''>Invalid address! Cannot place map pin.</div>
                {location.name !== UNKNOWN_LOCATION_NAME &&
                    <Button size='sm' className='my-1'
                            variant='outline-danger'
                            href={location.link} target='_blank'>Edit in Portal</Button>}
              </Alert>}
            <dt className=''>Distance from PWI</dt>
            <dd
              className='ms-1'>{location.distanceFromPWI && location.distanceFromPWI !== '?' ? location.distanceFromPWI : '(unknown)'}</dd>
            <dt className="">Contact</dt>
            {location.contact ? <dd className='ms-1'>
              <p className='mb-0'>{location.contact.name}</p>
              {location.contact.phone && <a href={`tel:${Number(location.contact.phone.replace(/[^0-9]/g, ""))}`}><p
                  className='mb-0'>{location.contact.phone}</p></a>}
            </dd> : <dd>No contact specified</dd>}
          </dl>
          <p className='fs-5 fw-bold mb-1'>Shipments ({location.shipments.length})</p>
        </div>
        <div className="p-0 m-0">
          <ShipmentsAccordion shipments={location.shipments}/>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
};

