import {ICoordinates} from "../data/data-types";

export function ParseDMS(input : string) {
  const parts = input.split(/[^\w.]+/);
  const lat = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
  const lng = ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7]);

  return {
    lat : lat,
    lng: lng,
  } as ICoordinates
}


function ConvertDMSToDD(degrees:string, minutes:string, seconds:string, direction:string) {
  let dd = Number(degrees) + Number(minutes)/60 + Number(seconds)/(60*60);

  if (direction === "S" || direction === "W") {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
}