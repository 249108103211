import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Card, Col, Container} from "react-bootstrap";
import {
  onAuthStateChanged,
  getAuth,
  signInWithCustomToken,
  User,
  signInWithPopup,
  GoogleAuthProvider
} from "firebase/auth";
import {auth} from "./firebase";
import {checkAuthStatus, monitorAuthState, signOut, onAuthSubdomain} from "./utils/authUtils";
import {LoadingOverlay} from "./components/loadingOverlay";
import Spinner from "react-bootstrap/Spinner";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


function RootComponent() {

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function verifyAuth() {

      try {
        const token = await checkAuthStatus();
        await signInWithCustomToken(auth, token);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setUser(null);
        setLoading(false);
      }
    }

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        // call monitorAuthState each time the user is logged in
        if (onAuthSubdomain) {
          monitorAuthState(user);
        }

        // identify user with userguiding
        // @ts-ignore
        window['userGuiding'].identify(user?.email, {
          name: user?.displayName,
          email: user?.email,
        })

      } else {
        setUser(null);
      }
    });

    verifyAuth();
  }, []);

  const currentURL = window.location.href;

  async function signInWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (e) {
      console.error("error signing in", e);
    }
  }

  if (!user) {
    return (
      <Container fluid className='text-center d-flex justify-content-center min-vh-100 bg-body-secondary'>
        {loading
          ? <div className="d-flex justify-content-center align-items-center flex-grow-1">
            <Spinner animation="border" role="status"/>
            <span className="ms-2 fs-3">Loading...</span>
          </div>
          : <Col>
            <Card className='d-inline-block mb-auto p-3 m-5' style={{minWidth: '20rem'}}>
              <Card.Body>
                <Card.Title>PWI Shipping Map</Card.Title>
                <p className='text-secondary'>please sign in to continue</p>
                {onAuthSubdomain
                  ? <Button href={`https://auth.pwiworks.app/sign-in?redirect=${currentURL}`}>Sign In</Button>
                  : <Button onClick={signInWithGoogle}>Sign In</Button>}
              </Card.Body>
            </Card>
          </Col>
        }
      </Container>
    );
  }
  if (!user.email?.includes('@pwiworks.com')) {
    const auth = getAuth();
    return (
      <Container fluid className='text-center d-flex justify-content-center min-vh-100 bg-body-secondary'>
        <Col>
          <Card className='d-inline-block mb-auto p-3 m-5'>
            <Card.Body>
              <Card.Title>Access Denied</Card.Title>
              <p>You do not have access to this site. Please use a PWI email to sign in.</p>
              <Button variant="outline-danger" onClick={() => {
                signOut();
              }} className='mx-1'>OK</Button>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    )
  }
  return (
    <App/>
  );
}

root.render(
  <React.StrictMode>
    <RootComponent/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
