import React, {useEffect, useState} from 'react';
import IDataFilters from '../data/iDataFilters';
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Form,
  InputGroup,
  Stack,
  ToastContainer,
  ToggleButton,
  ButtonGroup
} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {SalesChannel} from "../data/data-types";
import {DEFAULT_FILTERS, DEFAULT_SALES_CHANNELS} from "../constants";

interface FiltersModalProps {
  filters: IDataFilters;
  onChange: (filters: IDataFilters) => void;
  className?: string;
  direction?: 'horizontal' | 'vertical';
}

export const FiltersCard = ({filters, onChange, className, direction}: FiltersModalProps) => {
  const showOptions = ['all', 'ship only', 'installed'];

  const [filtersState, setFiltersState] = useState(filters);
  const [filtersAreDefault, setFiltersAreDefault] = useState(true);
  const [conflictingDates, setConflictingDates] = useState(false);
  const [allSalesChannelsShown, setAllSalesChannelsShown] = useState(true);
  const iconURL = 'https://maps.gstatic.com/mapfiles/ms2/micons/';
  const legendData = [
    {icon: `${iconURL}purple-dot.png`, label: "Selected"},
    {icon: `${iconURL}red.png`, label: "< 1 week"},
    {icon: `${iconURL}orange.png`, label: "1-2 weeks"},
    {icon: `${iconURL}yellow.png`, label: "3-5 weeks"},
    {icon: `${iconURL}green.png`, label: "6-15 weeks"},
    {icon: `${iconURL}blue.png`, label: "15+ weeks"},
  ];

  function areAllSalesChannelsShown(): boolean {
    return filtersState.salesChannels?.length === DEFAULT_SALES_CHANNELS.length;
  }

  function doFiltersMatchDefault(): boolean {
    return Object.entries(DEFAULT_FILTERS).every(([key, value]) => {
      const stateValue = filtersState[key as keyof IDataFilters];
      if (Array.isArray(value) && Array.isArray(stateValue)) {
        return [...value].sort().toString() === [...stateValue].sort().toString();
      }
      return stateValue === value;
    });
  }

  useEffect(() => {
    onChange(filtersState);
    setAllSalesChannelsShown(areAllSalesChannelsShown());
    setFiltersAreDefault(doFiltersMatchDefault());
  }, [filtersState, onChange]);

  useEffect(() => {
    setConflictingDates(!!(filtersState.onOrAfter && filtersState.onOrBefore && filtersState.onOrAfter > filtersState.onOrBefore));
  }, [filtersState.onOrAfter, filtersState.onOrBefore]);

  function toggleShowProduction() {
    setFiltersState(prevState => ({...prevState, hideNotToProduction: !prevState.hideNotToProduction}));
  }

  function resetFilters() {
    setFiltersState(DEFAULT_FILTERS);
  }

  function toggleSalesChannel(salesChannel?: SalesChannel) {
    setFiltersState(prevState => {
      if (salesChannel === undefined) {
        // If salesChannel is undefined, set salesChannels to undefined
        return {...prevState, salesChannels: DEFAULT_FILTERS.salesChannels};
      } else {
        // If salesChannels is undefined, initialize it as an empty array
        const salesChannels = prevState.salesChannels || [];

        if (salesChannels.includes(salesChannel)) {
          // If salesChannels includes the salesChannel, remove it
          return {...prevState, salesChannels: salesChannels.filter(channel => channel !== salesChannel)};
        } else {
          // If salesChannels does not include the salesChannel, add it
          return {...prevState, salesChannels: [...salesChannels, salesChannel]};
        }
      }
    });
  }

  function setOnOrAfter(dateString: string) {
    if (dateString !== '' && dateString !== undefined) {
      const date = new Date(dateString);
      setFiltersState(prevState => ({...prevState, onOrAfter: date}));
    } else {
      setFiltersState(prevState => ({...prevState, onOrAfter: undefined}));
    }
  }

  function setOnOrBefore(dateString: string) {
    if (dateString !== '' && dateString !== undefined) {
      const date = new Date(dateString);
      setFiltersState(prevState => ({...prevState, onOrBefore: date}));
    } else {
      setFiltersState(prevState => ({...prevState, onOrBefore: undefined}));
    }
  }


  return (
      <ToastContainer position='top-end' className={`p-2 pe-auto ${className}`}>
        <Card className=''>
          <Card.Body className='p-1'>
            <Stack direction={direction ?? 'horizontal'} className='d-flex justify-content-between gap-1 flex-grow-0'>
              <InputGroup className='position-relative'>
                <InputGroup.Text className='bg-primary text-light'>On or After</InputGroup.Text>
                <Form.Control
                  type='date'
                  onInput={(e) => setOnOrAfter(e.currentTarget.value)}
                  value={filtersState.onOrAfter?.toISOString().split('T')[0] ?? ''}
                  isInvalid={conflictingDates}
                />
              </InputGroup>
              <InputGroup className='position-relative' hasValidation>
                <InputGroup.Text className='bg-primary text-light'>On or Before</InputGroup.Text>
                <Form.Control
                  type='date'
                  onInput={(e) => setOnOrBefore(e.currentTarget.value)}
                  value={filtersState.onOrBefore?.toISOString().split('T')[0] ?? ''}
                  isInvalid={conflictingDates}
                />
                <Form.Control.Feedback tooltip type='invalid' className=''>Date selections hide all
                  results!</Form.Control.Feedback>
              </InputGroup>
              <Dropdown autoClose='outside'>
                <Dropdown.Toggle variant="primary" id="dropdown-basic" className='text-nowrap w-100'>
                  Sales Channel
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {DEFAULT_SALES_CHANNELS.map((salesChannel) => (
                    <Dropdown.Item key={salesChannel} onClick={() => toggleSalesChannel(salesChannel)}>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id={`${salesChannel}-switch`}
                        label={salesChannel}
                        checked={filtersState.salesChannels?.includes(salesChannel)}
                      />
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Header className='p-0 d-flex justify-content-center'>
                    <Button variant='outline-primary' size='sm' onClick={() => toggleSalesChannel()}
                            disabled={allSalesChannelsShown}>Show All</Button>
                  </Dropdown.Header>
                </Dropdown.Menu>
              </Dropdown>
              <ButtonGroup>
                {showOptions.map((option, index) =>
                  <ToggleButton
                    id={`tbg-radio-${option.replace(" ", "-")}`}
                    variant='outline-primary'
                    type='radio'
                    value={option}
                    checked={filtersState.showTypes === option}
                    onClick={() => setFiltersState(prevState => ({
                      ...prevState,
                      showTypes: option as 'all' | 'ship only' | 'installed'
                    }))}
                    className='text-nowrap text-capitalize'>{option}</ToggleButton>)}
              </ButtonGroup>
              <Button onClick={toggleShowProduction}
                      className='text-nowrap'>{filtersState.hideNotToProduction ? 'Show Pre-Production' : 'Hide Pre-Production'}</Button>
              <Button onClick={resetFilters} variant={filtersAreDefault ? 'outline-danger' : 'danger'}
                      className='text-nowrap'
                      disabled={filtersAreDefault}>Clear
                Filters</Button>
            </Stack>
            {direction === "vertical" && <div className={`d-flex flex-column flex-grow-0 flex-wrap gap-1 mt-2`}>
              {legendData.map((badge, index) => (
                <Badge key={index} pill bg="light" className=" text-dark">
                  <img src={badge.icon} height="20em" alt={badge.label}/>
                  <span>{badge.label}</span>
                </Badge>
              ))}
            </div>}
          </Card.Body>
        </Card>
        {direction === "horizontal" &&
            <div className={`d-flex space-between flex-wrap gap-1 mt-2 justify-content-end`}>
              {legendData.map((badge, index) => (
                <Badge key={index} pill bg="light" className=" text-dark">
                  <img src={badge.icon} height="20em" alt={badge.label}/>
                  <span>{badge.label}</span>
                </Badge>
              ))}
            </div>}
      </ToastContainer>
  );
}
