import React from "react";
import {ILocation} from "../data/data-types";
import {LocationCard} from "./locationCard";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Col, Row, Container} from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";

interface LocationsListProps {
  locations: ILocation[] | null;
  onSelectLocation: (location: ILocation, index: number) => void;
  onSignOut: () => void;
}

export const LocationsList: React.FC<LocationsListProps> = ({locations, onSelectLocation, onSignOut}) => {


  // Sort locations by earliestDueDate and then by name
  const sortedLocations = locations ? [...locations].sort((a, b) => {
      if (a.earliestDueDate && b.earliestDueDate) {
        if (a.earliestDueDate < b.earliestDueDate) {
          return -1;
        } else if (a.earliestDueDate > b.earliestDueDate) {
          return 1;
        }
      } else if (a.earliestDueDate) {
        return -1; // a has a date, but b doesn't, so a comes first
      } else if (b.earliestDueDate) {
        return 1; // b has a date, but a doesn't, so b comes first
      }
      // If earliestDueDate is the same or both are undefined, sort by name
      return a.name.localeCompare(b.name);
    })
    : undefined;

  return (
    <Container fluid className='d-flex flex-column p-0 h-100'>
      <Row className='flex-wrap-reverse m-0'>
        <Col className=''>
          <h1 className=''>Locations</h1>
        </Col>
        <Col xs="auto" className='d-flex align-items-center'>
          <Button variant="outline-danger" className='text-nowrap mt-1 mb-1 ms-auto me-auto' onClick={onSignOut}>Sign
            Out</Button>
        </Col>
      </Row>
      {locations === null &&
          <>
            <hr className='m-0 opacity-100'/>
            <div className="d-flex justify-content-center align-items-center flex-grow-1">
              <Spinner animation="border" role="status"/>
              <span className="sr-only ms-2 fs-3">Loading...</span>
            </div>
          </>}
      {locations && locations.length === 0 &&
          <>
            <hr className='m-0 opacity-100'/>
            <div className="d-flex justify-content-center align-items-center flex-grow-1">
              <span className="sr-only ms-2 fs-3">all locations filtered...</span>
            </div>
          </>}
      {locations && locations.length > 0 && <Row className='m-0 overflow-auto'>
        {sortedLocations?.map((location, index) => (
          <LocationCard key={index} location={location} onClick={() => {
            const originalIndex = locations.findIndex(l => l === location);
            onSelectLocation(location, originalIndex);
          }}/>
        ))}
      </Row>}
    </Container>
  );
};
