import React from 'react';
import {Container} from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";

interface LoadingOverlayProps {
  className?: string;
  showText?: boolean;
}

export const LoadingOverlay = ({className, showText}: LoadingOverlayProps) => {

  return (
    <Container fluid className={`position-absolute top-0 start-0 h-100 bg-dark d-flex justify-content-center align-items-center opacity-75 ${className}`}>
        <Spinner animation="border" role="status" variant='light'/>
        <span className={`${showText ? '' : 'sr-only'} ms-2 fs-3 text-white`}>Loading...</span>
    </Container>
  )
}