import React from 'react';
import {Badge, Col} from "react-bootstrap";

interface BadgesColumnProps {
  preProduction: boolean;
  preProductionSuffix?: string;
  overdue: boolean;
  hold: boolean;
  holdSuffix?: string;
  className?: string;
  installedByPWI: boolean;
  installedSuffix?: string;
}

export const BadgesColumn = ({preProduction, overdue, hold, holdSuffix, preProductionSuffix, className, installedByPWI, installedSuffix}: BadgesColumnProps) => {
  return (
    <Col className={`align-self-stretch d-flex flex-column align-items-end justify-content-center gap-1 ${className}`}>
      {preProduction && <Badge pill bg="info" text='dark' className=''>{preProductionSuffix ? `PRE-PROD. ${preProductionSuffix.trim()}` : 'PRE-PRODUCTION'}</Badge>}
      {overdue && <Badge pill bg="warning" text="dark" className=''>OVERDUE</Badge>}
      {hold && <Badge pill bg="danger" text="light" className=''>HOLD{holdSuffix ? ` ${holdSuffix.trim()}` : ''}</Badge>}
      {installedByPWI && <Badge pill bg="success" text="light" className=''>INSTALL{installedSuffix ? ` ${installedSuffix.trim()}` : ''}</Badge>}
    </Col>
  )
}