import React, {useState, useEffect} from 'react';
import './App.css';
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Col, Row,} from "react-bootstrap";
import {ILocation} from "./data/data-types";
import {refreshData, getLocations} from "./data/airtable-data";
import {LocationDetail} from './components/locationDetail';
import {LocationsList} from './components/locationsList';
import RefreshButtonCard from './components/refreshButtonCard';
import RefreshModal from "./components/refreshModal";
import IDataFilters from "./data/iDataFilters";
import {FiltersCard} from "./components/filtersCard";
import {DEFAULT_FILTERS} from "./constants";
import {signOut} from "./utils/authUtils";
import {SignOutCard} from "./components/signOutCard";
import {LoadingOverlay} from "./components/loadingOverlay";
import Cookies from 'js-cookie';


// Hawk Integrations
function getMarkerColor(earliestDueDate: string | undefined): string {
  if (!earliestDueDate) {
    return 'blue'; // default color if no date is provided
  }

  const dueDate = new Date(earliestDueDate);
  const now = new Date();
  const oneWeek = 7 * 24 * 60 * 60 * 1000; // milliseconds in a week
  const weeksUntilDue = Math.round((dueDate.getTime() - now.getTime()) / oneWeek);

  if (weeksUntilDue < 1) {
    return 'red';
  } else if (weeksUntilDue >= 1 && weeksUntilDue < 2) {
    return 'orange';
  } else if (weeksUntilDue >= 2 && weeksUntilDue < 5) {
    return 'yellow';
  } else if (weeksUntilDue >= 5 && weeksUntilDue < 15) {
    return 'green';
  } else {
    return 'blue';
  }
}

/**
 * Retrieves filters from a cookie.
 * If the cookie is not found or cannot be parsed, it returns the default filters.
 *
 * @returns {IDataFilters} The filters retrieved from the cookie or the default filters.
 */
function getFiltersFromCookie(): IDataFilters {
  const filtersCookie = Cookies.get('filters');
  if (filtersCookie) {
    try {
      return {...DEFAULT_FILTERS, ...JSON.parse(filtersCookie)};
    } catch (error) {
      console.error('Error parsing filters from cookie:', error);
    }
  }
  return DEFAULT_FILTERS;
}

/**
 * Writes the given filters to a cookie.
 * The cookie will expire in 7 days.
 *
 * @param {IDataFilters} filters - The filters to be saved in the cookie.
 */
function writeFiltersToCookie(filters: IDataFilters): void {
  Cookies.set('filters', JSON.stringify(filters), {expires: 7}); // Cookie expires in 7 days
}


function App() {
  // State variable for the currently selected location
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);

  // State variable for the initial center of the map
  const [initialCenter, setInitialCenter] = useState({lat: 41.442650, lng: -85.999950});

  // State variable to track if the user has interacted with the map
  const [userInteracted, setUserInteracted] = useState(false);

  // State variable to control the visibility of the refresh popup
  const [showRefreshPopup, setShowRefreshPopup] = useState(false);

  // State variable for the index of the currently selected marker
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(null);

  // State variable for the filters applied to the locations
  const [filters, setFilters] = useState<IDataFilters>(getFiltersFromCookie());

  // State variable to track if it's the first load of the map
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // State variable for the list of locations
  const [locations, setLocations] = useState<ILocation[] | null>(null);

  // State variable for the last updated time
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

  // State variable to control the visibility of the location detail
  const [showDetail, setShowDetail] = useState<boolean>(false);

  // Combined useEffect hook to run when component mounts and to update the ref every time the map is panned
  useEffect(() => {
    // Refresh the data on mount
    handleRefresh();

    // Show the refresh popup after 10 minutes
    const timeoutId = setTimeout(() => {
      setShowRefreshPopup(true);
    }, 600000); // 10 minutes

    // Update the ref every time the map is panned
    const interval = setInterval(() => {
      if (window.google && window.google.maps) {
        // Assume `map` is a reference to your Map component
        // This part needs access to the actual map object to get the center, which might involve using callbacks or other methods provided by your map library
        // Example (this will depend on how you can access map instance in vis.gl/react-google-maps):
        // currentCenter.current = map.getCenter().toJSON();
      }
    }, 1000);

    // Cleanup function to clear the timeout and interval if the component is unmounted
    return () => {
      clearTimeout(timeoutId);
      clearInterval(interval);
    };
  }, []);

  // Effect hook to update the locations when the filters change
  useEffect(() => {
    setShowDetail(false);
    setLocations(locations === null ? null : getLocations(filters));
    // persist filters across sessions
    writeFiltersToCookie(filters);
  }, [filters]); // Run this effect whenever filters changes


  // Function to refresh the data and close the refresh popup
  const handleRefreshAndClosePopup = () => {
    handleRefresh();
    setShowRefreshPopup(false);
  };


  /**
   * Refreshes the data and updates the locations and last updated time.
   * It also sets the visibility of the location detail to false, clears the locations,
   * and sets the first load flag to true.
   */
  const handleRefresh = () => {
    setShowDetail(false); // Hide location detail
    setLocations(null); // Clear locations
    refreshData().then(() => {
      setLocations(getLocations(filters)); // Update locations with refreshed data
      setIsFirstLoad(true); // Set first load flag to true
    });
    setLastUpdated(new Date()); // Update last updated time
  };

  /**
   * Handles user interaction with the map.
   * It sets the user interacted flag to true and the first load flag to false.
   */
  const handleUserInteraction = () => {
    setUserInteracted(true); // Set user interacted flag to true
    setIsFirstLoad(false); // Set first load flag to false
  };

  /**
   * Handles the selection of a location.
   * It sets the selected location, shows the location detail, pans smoothly to the location,
   * sets the user interacted flag to false, and sets the selected marker index.
   *
   * @param {ILocation} location - The selected location.
   * @param {number} index - The index of the selected location.
   */
  const handleSelectLocation = (location: ILocation, index: number, shouldCenter: boolean = true) => {
    console.log("handleSelectLocation called with index:", index); // Log the index
    setSelectedLocation(location); // Set the selected location
    setShowDetail(true); // Show location detail
    if (shouldCenter) {
      smoothPanTo(location); // Pan smoothly to the location only if shouldCenter is true
    }
    // Set the selected marker index
    setSelectedMarkerIndex(index);
  };

  /**
   * Pans smoothly to a new location.
   * It sets the user interacted flag to false, calculates the steps and increments for panning,
   * and then pans to the new location in steps. After the last step, it allows user interaction.
   *
   * @param {ILocation} newLocation - The new location to pan to.
   */
  const smoothPanTo = (newLocation: ILocation) => {
    if (newLocation.coordinates && 'lat' in newLocation.coordinates && 'lng' in newLocation.coordinates) {
      setUserInteracted(false); // Set user interacted flag to false
      const steps = 20; // Number of steps for panning
      const stepLat = (newLocation.coordinates.lat - initialCenter.lat) / steps; // Increment for latitude
      const stepLng = (newLocation.coordinates.lng - initialCenter.lng) / steps; // Increment for longitude

      for (let i = 1; i <= steps; i++) {
        setTimeout(() => {
          setInitialCenter(prevCenter => ({
            lat: prevCenter.lat + stepLat, lng: prevCenter.lng + stepLng
          })); // Update the center of the map
          if (i === steps) {
            setUserInteracted(true); // Allow user interaction after the last step
          }
        }, i * 20);
      }
    } else {
      // Handle the case where newLocation.coordinates is an Error or null
    }
  };

  /**
   * Determines the API key to use based on the current hostname.
   * If the hostname ends with '.pwiworks.app', it uses the browser API key.
   * Otherwise, it uses the restricted API key.
   */
  let apiKey;
  if (window.location.hostname.endsWith('.pwiworks.app')) {
    apiKey = 'AIzaSyCBK8o_CuIJNfmjfIyrseypsqKmPUVo3R8'; // browser API key
  } else {
    apiKey = 'AIzaSyC0izKdRIXd4aJtib48aOZyKYalcdXYtHY'; // restricted API key
  }

  return (<Container fluid>
    <Row className=''>
      <Col md={4} xl={3} xxl={2} className="d-none d-md-flex bg-light px-0" style={{height: '100dvh'}}>
        <LocationsList onSignOut={() => signOut()} locations={locations} onSelectLocation={handleSelectLocation}/>
        {selectedLocation &&
            <>
              <LocationDetail className='d-md-none'
                              placement='bottom'
                              location={selectedLocation}
                              show={showDetail}
                              onHide={() => setShowDetail(false)}
                              onExited={() => {
                                setSelectedLocation(null);
                                setSelectedMarkerIndex(null);
                              }}/>
              <LocationDetail className='d-none d-md-flex'
                              placement='start'
                              location={selectedLocation}
                              show={showDetail}
                              onHide={() => setShowDetail(false)}
                              onExited={() => {
                                setSelectedLocation(null);
                                setSelectedMarkerIndex(null);
                              }}/>
            </>}
      </Col>
      <Col xs={12} md={8} xl={9} xxl={10} className="position-relative p-0 m-0" style={{height: '100dvh'}}>
        <FiltersCard className='d-none d-lg-block d-xxl-none' filters={filters} onChange={setFilters}
                     direction='vertical'></FiltersCard>
        <FiltersCard className='d-none d-xxl-block' filters={filters} onChange={setFilters}
                     direction='horizontal'></FiltersCard>
        {selectedLocation === null &&
            <RefreshButtonCard className='d-none d-md-block' lastUpdated={lastUpdated} handleRefresh={handleRefresh}
                               disabled={locations === null}></RefreshButtonCard>}
        <SignOutCard onSignOut={() => signOut()} className='d-md-none'></SignOutCard>
        <header className="App-header">
          <APIProvider apiKey={apiKey}>
            <>
              <Map
                style={{width: '100%', height: '100dvh'}}
                center={userInteracted ? undefined : initialCenter}
                zoom={isFirstLoad ? 6 : undefined} // Set zoom level to 6 if it's the first load or refresh, otherwise allow user to change it
                defaultCenter={initialCenter}
                defaultZoom={6}
                gestureHandling={'greedy'}
                disableDefaultUI={false}
                mapTypeControl={true}
                onDragstart={handleUserInteraction}
                onZoomChanged={handleUserInteraction}
                fullscreenControl={false}
                maxZoom={21}
                restriction={{
                  latLngBounds: {
                    north: 70,
                    south: 0,
                    east: -20,
                    west: -180
                  },
                  strictBounds: true,
                }}
              >
                {/*  {locations?.map((location, index) => {*/}
                {/*    if (location.coordinates && 'lat' in location.coordinates && 'lng' in location.coordinates) {*/}
                {/*      let title = `${location.name}\nNo due date`;*/}
                {/*      if (location.earliestDueDate) {*/}
                {/*        const earliestDueDate = new Date(location.earliestDueDate);*/}
                {/*        title = `${location.name}\nNext due date: ${earliestDueDate.toLocaleDateString()}`;*/}
                {/*      }*/}
                {/*      return (*/}
                {/*        <Marker*/}
                {/*          key={index}*/}
                {/*          title={title}*/}
                {/*          position={{lat: location.coordinates.lat, lng: location.coordinates.lng}}*/}
                {/*          icon={{*/}
                {/*            url: index === selectedMarkerIndex*/}
                {/*              ? 'https://maps.gstatic.com/mapfiles/ms2/micons/purple-dot.png' // Use a different icon for the selected marker*/}
                {/*              : 'https://maps.gstatic.com/mapfiles/ms2/micons/' + getMarkerColor(location.earliestDueDate) + '.png'*/}
                {/*          }}*/}
                {/*          onClick={() => handleSelectLocation(location, index, false)} // Pass the index to the click handler*/}
                {/*        />*/}
                {/*      );*/}
                {/*    } else {*/}
                {/*      // Handle the case where location.coordinates is an Error or null*/}
                {/*      return null;*/}
                {/*    }*/}
                {/*  })}*/}
                {/*</Map>*/}
                {locations?.map((location, index) => {
                  if (location.coordinates && 'lat' in location.coordinates && 'lng' in location.coordinates) {
                    let title = `${location.name}\nNo due date`;
                    if (location.earliestDueDate) {
                      const earliestDueDate = new Date(location.earliestDueDate);
                      title = `${location.name}\nNext due date: ${earliestDueDate.toLocaleDateString()}`;
                    }

                    // Calculate the offset for overlapping markers
                    const offset = 0.0001; // This value might need to be adjusted
                    // const count = locations.filter(l => l.coordinates !== null && l.coordinates.lat === location?.coordinates.lat && l.coordinates.lng === location.coordinates.lng).length;
                    const latOffset = (index % 2 === 0 ? 1 : -1) * offset * Math.floor(index / 2);
                    const lngOffset = (index % 2 === 0 ? 1 : -1) * offset * Math.floor(index / 2);

                    return (
                      <Marker
                        key={index}
                        title={title}
                        position={{
                          lat: location.coordinates.lat + latOffset,
                          lng: location.coordinates.lng + lngOffset
                        }}
                        icon={{
                          url: index === selectedMarkerIndex
                            ? 'https://maps.gstatic.com/mapfiles/ms2/micons/purple-dot.png' // Use a different icon for the selected marker
                            : 'https://maps.gstatic.com/mapfiles/ms2/micons/' + getMarkerColor(location.earliestDueDate) + '.png'
                        }}
                        onClick={() => handleSelectLocation(location, index, false)} // Pass the index to the click handler
                      />
                    );
                  } else {
                    // Handle the case where location.coordinates is an Error or null
                    return null;
                  }
                })}
              </Map>
            </>
          </APIProvider>
        </header>
        {locations === null && <LoadingOverlay className='d-md-none'></LoadingOverlay>}
      </Col>
    </Row>
    <RefreshModal
      show={showRefreshPopup}
      onHide={() => setShowRefreshPopup(false)}
      onRefresh={handleRefreshAndClosePopup}
    />
  </Container>);
}

export default App;
