export function isOverdue(date?: string) {
  if (!date) return false;
  const [year, month, day] = date.split('-').map(Number);
  const comparisonDate = new Date(year, month - 1, day);
  const now = new Date();
  now.setHours(0,0,0,0);
  return comparisonDate < now;
}

export function getFriendlyDate(date?: string) {
  if (!date) return '(no due date)';
  const [year, month, day] = date.split('-').map(Number);
  return new Date(year, month - 1, day).toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'});
}
