// locationDetail.tsx
import React from 'react';
import {ILocation} from "../data/data-types";
import {Alert, Button, Col} from "react-bootstrap";
import {isOverdue, getFriendlyDate} from "../dateHelpers";
import {BadgesColumn} from "./badgesColumn";

interface LocationDetailProps {
  location: ILocation;
  onClick: () => void;
}

export const LocationCard = ({location, onClick}: LocationDetailProps) => {

  const hasOverdueItems = location.shipments.some(shipment => isOverdue(shipment.dueDate) && !shipment.holdForPayment);
  const onHoldCount = location.shipments.reduce((count, shipment) => shipment.holdForPayment ? count + 1 : count, 0);
  const allOnHold = onHoldCount === location.shipments.length;

  const preProductionCount = location.shipments.reduce((count, shipment) => !shipment.toProduction ? count + 1 : count, 0);
  const preProductionSuffix = preProductionCount === location.shipments.length ? '(ALL)' : `(${preProductionCount} of ${location.shipments.length})`;

  const installedCount = location.shipments.reduce((count, shipment) => shipment.installedByPWI ? count + 1 : count, 0);
  const installedSuffix = installedCount === location.shipments.length ? '(ALL)' : `(${installedCount} of ${location.shipments.length})`;

  const strikeThroughDate = (isOverdue(location.earliestDueDate) && !hasOverdueItems) || allOnHold;

  const holdSuffix = allOnHold ? 'ALL' : `(${onHoldCount} of ${location.shipments.length})`;

  return (
    <div className='p-0'>
      <Button
        className='container-fluid text-start rounded-0 border-end-0 border-start-0 border-bottom-0'
        variant='outline-dark' onClick={onClick}>
        <Col className='d-flex flex-row'>
          <div className='pe-2 flex-grow-1 me-auto'>
            <div className="fw-bold fs-6">{location.name}</div>
            <div className=''>({location.shipments.length}) shipment{location.shipments.length > 1 ? 's' : ''}</div>
            <div className=''>
              <span>Next: </span>
              {location.earliestDueDate
                ? <span
                  className={`${strikeThroughDate && 'text-decoration-line-through'}`}>{getFriendlyDate(location.earliestDueDate)}</span>
                : <span>(no due date specified)</span>}
              {strikeThroughDate && <span> (on hold)</span>}
            </div>
          </div>
          <BadgesColumn preProduction={preProductionCount > 0}
                        preProductionSuffix={preProductionSuffix}
                        overdue={hasOverdueItems}
                        hold={onHoldCount > 0}
                        holdSuffix={holdSuffix}
                        installedByPWI={installedCount > 0}
                        installedSuffix={installedSuffix}></BadgesColumn>
        </Col>
        {location.coordinates == null &&
            <Alert key='invalid' variant='danger' className='fw-bold mt-1 mb-1 py-2'>invalid address</Alert>}
      </Button>
    </div>
  );
}