import Airtable from 'airtable';
import {IAddress, IAttachment, IContact, ICoordinates, ILocation, IShipment, SalesChannel} from './data-types';
import IDataFilters from "./iDataFilters";
import {ParseDMS} from "../utils/parseDms";
import {UNKNOWN_LOCATION_NAME} from "../constants";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../firebase";

interface Schema {
  [key: string]: Base;
}

interface Base {
  id: string;
  tables: { [key: string]: Table };
}

interface Table {
  id: string;
  fields: { [key: string]: string };
  views?: { [key: string]: string };
}


const schema: Schema = {
  projects: {
    id: 'appi7MgUiiolgcHbB',
    tables: {
      buildings: {
        id: 'tblO4iql7oEIomLUf',
        fields: {
          name: 'Name',
          address: 'Address Line 1',
          city: 'City',
          state: 'State',
          zip: 'Postal Code',
          coordinates: 'geoDataCoordinates',
          exactCoordinates: 'Exact Coordinates',
          distanceFromPWI: 'distanceFromPWI',
          link: 'shippingPortalLink',
          contact: 'Contact' // link to contacts
        },
        views: {
          shippingMapView: 'viwBLJDBI30f7Npcc'
        }
      },
      employees: {
        id: 'tblJXUvXtsIbGoTOf',
        fields: {
          name: 'Full Name',
        },
        views: {
          shippingMapView: 'viwBLJDBI30f7Npcc'
        }
      },
      contacts: {
        id: 'tblD4Ccp9VGGCxxKK',
        fields: {
          name: 'Name',
          phone: 'Phone'
        },
        views: {
          shippingMapView: 'viw1eH23L9BAFWco5'
        }
      },
      travelers: {
        id: 'tblwghYpqK2iz8tx1',
        fields: {
          projectId: 'Project ID',
          projectName: 'Project Nickname',
          name: 'Description',
          brand: 'Sold By (brand) (from Project)', // link to brands
          manager: 'Manager', // link to employees
          status: 'Status',
          phase: 'Phase (from status)',
          location: 'Work Location(s)', // multiple link to buildings
          link: 'shippingPortalLink',
          shippingLists: 'Shipping/Loading List(s)',
          dueDate: 'Due Date',
          paymentHold: 'paymentHoldBeforeShip',
          description: 'Description',
          shippingInstructions: 'shippingInstructions',
          installedByPWI: 'installedByPWI?'
        },
        views: {
          forShippingMap: 'viwB1t43nltrriYL6'
        }
      },
      brands: {
        id: 'tblFNB6vGJGFDoxht',
        fields: {
          name: 'Name',
        },
      }
    }
  },
  shipping: {
    id: 'app4DNe7ZFvAaKwQn',
    tables: {
      shippingRequests: {
        id: 'tblr5SdKGM5t69FvE',
        fields: {
          id: 'Request ID',
          source: 'orderSourceString',
          status: 'Status',
          contactName: 'Address - Contact',
          contactPhone: 'Address - Phone',
          company: 'Address - Company',
          address: 'Address - Line 1',
          addressLink: 'Address - Portal Link',
          distanceFromPWI: 'Address - Distance From PWI',
          coordinates: 'Address - Coordinates',
          city: 'Address - City',
          state: 'Address - State',
          zip: 'Address - Postal Code',
          shippingLists: 'Packing List',
          link: 'Request Portal Link',
          dueDate: 'Ship By',
          customerNotes: 'Notes from Customer',
          shippingNotes: 'Notes from Shipping'
        },
        views: {
          shippingMapView: 'viwWKQnFpqvzYBpm9'
        }
      }
    }
  }
};

// Refactor getAccessToken to return a Promise that resolves to the Airtable token
async function getAccessToken(): Promise<string> {
  const docRef = doc(db, "env", "shippingMap");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const airtableApiToken = docSnap.data().airtableApiToken;
    if (!airtableApiToken) {
      throw new Error('Airtable API token not found');
    }
    return airtableApiToken;
  }
  throw new Error('Airtable API token not found');
}

// Initialize Airtable and use the resolved instance
let projectsBase: Airtable.Base;
let shippingBase: Airtable.Base;

async function initializeData(): Promise<void> {
  try {
    const token = await getAccessToken();
    const airtable = new Airtable({ apiKey: token });
    projectsBase = airtable.base(schema.projects.id);
    shippingBase = airtable.base(schema.shipping.id);
  } catch (error) {
    console.error('Error initializing Airtable:', error);
  }
}

/**
 * Fetches shipping request data from the Airtable base and returns a Promise that resolves to an array of ILocation objects.
 * Each ILocation object represents a shipping request, with the address geocoded into coordinates.
 *
 * @returns {Promise<ILocation[]>} A Promise that resolves to an array of ILocation objects representing the shipping requests.
 */
async function fetchShippingRequests(): Promise<ILocation[]> {
  if (!shippingBase) {
    throw new Error('Airtable base not initialized');
  }

  // Fetch all shipping requests from the Airtable base
  const shippingRequests = await shippingBase(schema.shipping.tables.shippingRequests.id)
    .select({
      view: schema.shipping.tables.shippingRequests.views?.shippingMapView,
      fields: Object.values(schema.shipping.tables.shippingRequests.fields)
    }).all();

  const tableFields = schema.shipping.tables.shippingRequests.fields;

  // Map each shipping request record to a Promise that resolves to an ILocation object representing the shipping request
  return shippingRequests.map((record) => {
    const recordFields = record.fields;

    // Construct the shipment data object
    const shipmentData: IShipment = {
      dueDate: recordFields[tableFields.dueDate]
        ? (recordFields[tableFields.dueDate] as string).substring(0, 10)
        : undefined,
      holdForPayment: recordFields[tableFields.status] === 'On Hold - Waiting Payment',
      packingSlips: recordFields[tableFields.shippingLists] as IAttachment[],
      saleId: recordFields[tableFields.id] as string,
      portalLink: recordFields[tableFields.link] as string,
      status: recordFields[tableFields.status] as string,
      salesChannel: recordFields[tableFields.source] as SalesChannel,
      specialInstructions: recordFields[tableFields.customerNotes] as string,
      notes: recordFields[tableFields.shippingNotes] as string,
      toProduction: true,
      installedByPWI: false,
    };

    // Construct the address object
    const address: IAddress = {
      street: recordFields[tableFields.address] as string,
      city: recordFields[tableFields.city] as string,
      state: recordFields[tableFields.state] as string,
      zip: recordFields[tableFields.zip] as string,
    };

    // Construct the contact object, if it exists
    const contact: IContact | undefined = recordFields[tableFields.contactName] ? {
      name: recordFields[tableFields.contactName] as string,
      phone: recordFields[tableFields.contactPhone] as string,
    } : undefined;

    const recordCoordinatesString: string | undefined = recordFields[tableFields.coordinates] as string;
    const coordinates: ICoordinates | null = recordCoordinatesString ? {
      lat: parseFloat(recordCoordinatesString.split(',')[0]),
      lng: parseFloat(recordCoordinatesString.split(',')[1])
    } : null;

    // Fetch the geocoded coordinates for the address, then construct and return the ILocation object
    return {
      name: recordFields[tableFields.company] as string ?? contact?.name ?? shipmentData.saleId,
      address: address,
      coordinates: coordinates,
      contact: contact,
      distanceFromPWI: recordFields[tableFields.distanceFromPWI] as string,
      shipments: [shipmentData],
      link: recordFields[tableFields.addressLink] as string,
    } as ILocation;
  });
}

async function fetchContacts(): Promise<{ [key: string]: IContact }> {
  if (!projectsBase) {
    throw new Error('Airtable base not initialized');
  }
  // Fetch all buildings from the Airtable base
  const contacts = await projectsBase(schema.projects.tables.contacts.id)
    .select({
      view: schema.projects.tables.contacts.views?.shippingMapView,
      fields: Object.values(schema.projects.tables.contacts.fields)
    }).all();

  const tableFields = schema.projects.tables.contacts.fields;

  // Reduce the array of contacts to a single object
  return contacts.reduce((acc, record) => {
    const recordFields = record.fields;

    // Add a new property to the accumulator object for each contact
    acc[record.id] = {
      name: recordFields[tableFields.name] as string,
      phone: recordFields[tableFields.phone] as string,
    } as IContact;

    return acc;
  }, {} as { [key: string]: IContact });
}

/**
 * Fetches building data from the Airtable base and returns a Promise that resolves to an object.
 * Each key in the object is a building ID, and the value is an ILocation object representing the building.
 *
 * @returns {Promise<{ [key: string]: ILocation }>} A Promise that resolves to an object mapping building IDs to ILocation objects.
 */
async function fetchBuildings(contactsMap: { [key: string]: IContact }): Promise<{ [key: string]: ILocation }> {
  if (!projectsBase) {
    throw new Error('Airtable base not initialized');
  }

  // Fetch all buildings from the Airtable base
  const buildings = await projectsBase(schema.projects.tables.buildings.id)
    .select({
      view: schema.projects.tables.buildings.views?.shippingMapView,
      fields: Object.values(schema.projects.tables.buildings.fields)
    }).all();

  const tableFields = schema.projects.tables.buildings.fields;

  // Map each building record to a Promise that resolves to an object with a single property
  // The key is the building ID, and the value is an ILocation object representing the building
  // Wait for all promises to resolve, resulting in an array of objects
  return buildings.reduce((acc, record) => {
    const recordFields = record.fields;

    // Construct the address object
    const address: IAddress = {
      street: recordFields[tableFields.address] as string,
      city: recordFields[tableFields.city] as string,
      state: recordFields[tableFields.state] as string,
      zip: recordFields[tableFields.zip] as string,
    };

    // Parse the coordinates string into an ICoordinates object, if it exists
    const recordCoordinatesString: string | undefined = recordFields[tableFields.exactCoordinates] as string ?? recordFields[tableFields.coordinates] as string
    let coordinates: ICoordinates | null;
    if (recordCoordinatesString && /[a-zA-Z]/.test(recordCoordinatesString)) {
      coordinates = ParseDMS(recordCoordinatesString);
    } else {
      coordinates = recordCoordinatesString ? {
        lat: parseFloat(recordCoordinatesString.split(',')[0]),
        lng: parseFloat(recordCoordinatesString.split(',')[1])
      } : null;
    }

    // Construct the contact object, if it exists
    const contactRecordID = recordFields[tableFields.contact] as string;
    const contact: IContact | undefined = contactRecordID ? contactsMap[contactRecordID] : undefined;

    // Add the ILocation object to the accumulator object
    acc[record.id] = {
      name: recordFields[tableFields.name] as string,
      address: address,
      coordinates: coordinates,
      contact: contact,
      link: recordFields[tableFields.link] as string,
      distanceFromPWI: recordFields[tableFields.distanceFromPWI] as string,
      shipments: [],
    } as ILocation;

    return acc;
  }, {} as { [key: string]: ILocation });
}

/**
 * Fetches brand data from the Airtable base and returns a Promise that resolves to an object.
 * Each key in the object is a brand ID, and the value is the brand name.
 *
 * @returns {Promise<{ [key: string]: string }>} A Promise that resolves to an object mapping brand IDs to brand names.
 */
async function fetchBrands(): Promise<{ [key: string]: string }> {
  if (!projectsBase) {
    throw new Error('Airtable base not initialized');
  }

  // Fetch all brands from the Airtable base
  const brands = await projectsBase(schema.projects.tables.brands.id)
    .select({
      fields: Object.values(schema.projects.tables.brands.fields)
    }).all();

  const tableFields = schema.projects.tables.brands.fields;

  // Initialize an empty object to store the brands
  const brandsMap: { [key: string]: string } = {};

  // Iterate over each brand record
  brands.forEach((record) => {
    // Use the record ID as the key and the brand name as the value
    brandsMap[record.id] = record.fields[tableFields.name] as string;
  });

  // Return the brands map
  return brandsMap;
}


/**
 * Fetches traveler data from the Airtable base and updates the buildingsMap with the fetched data.
 *
 * This function performs the following steps:
 * 1. Fetches traveler data from the Airtable base.
 * 2. Iterates over each traveler record, constructing a shipment data object for each one.
 * 3. For each traveler record, it also gets the associated location IDs.
 * 4. For each location ID, it finds the corresponding building in the buildingsMap and adds the shipment data to it.
 * 5. Finally, it returns the updated buildingsMap as an array of ILocation objects.
 *
 * @param buildingsMap - An object where each key is a building ID and the value is an ILocation object representing the building.
 * @param brands - An object where each key is a brand ID and the value is the brand name.
 * @returns {Promise<ILocation[]>} A Promise that resolves to an array of ILocation objects representing the updated buildings.
 */
async function fetchTravelers(buildingsMap: { [key: string]: ILocation }, brands: {
  [key: string]: string
}): Promise<ILocation[]> {
  if (!projectsBase) {
    throw new Error('Airtable base not initialized');
  }

  // Fetch all travelers from the Airtable base
  const travelers = await projectsBase(schema.projects.tables.travelers.id)
    .select({
      view: schema.projects.tables.travelers.views?.forShippingMap,
      fields: Object.values(schema.projects.tables.travelers.fields)
    }).all();

  const tableFields = schema.projects.tables.travelers.fields;

  // Iterate over each traveler record
  travelers.forEach((record) => {
    const recordFields = record.fields;

    const saleIds = recordFields[tableFields.projectId] as string;

    const phaseString = recordFields[tableFields.phase] as string[];

    const toProduction = phaseString[0] !== 'Inception' && phaseString[0] !== 'Design';

    const installedByPWI = recordFields[tableFields.installedByPWI] as number === 1;

    // Construct the shipment data object
    const shipmentData: IShipment = {
      dueDate: (recordFields[tableFields.dueDate] as string).substring(0, 10),
      holdForPayment: recordFields[tableFields.paymentHold] as string === "1",
      packingSlips: recordFields[tableFields.shippingLists] as IAttachment[],
      saleId: saleIds[0],
      description: recordFields[tableFields.description] as string,
      portalLink: recordFields[tableFields.link] as string,
      status: recordFields[tableFields.status] as string,
      salesChannel: brands[recordFields[tableFields.brand] as string] as SalesChannel,
      specialInstructions: recordFields[tableFields.shippingInstructions] as string,
      toProduction: toProduction,
      installedByPWI: installedByPWI,
    };

    // Get the associated location IDs
    const locationIds: string[] = recordFields[tableFields.location] as string[];

    // This block of code handles the assignment of shipment data to the appropriate building.
    // If locationIds exist, it iterates over each locationId, finds the corresponding building in the buildingsMap,
    // and pushes the shipment data into the building's shipments array.
    // If locationIds do not exist (i.e., they are undefined), it checks if there's a building with the key 'undefined'.
    // If such a building exists, it pushes the shipment data into that building's shipments array.
    // If it doesn't exist, it creates a new building with the key 'undefined', an 'Undefined' name, the shipment data,
    // and an Error object as coordinates indicating that there's no address.
    if (locationIds) {
      // Iterate over each locationId
      locationIds.forEach((locationId) => {
        // Find the corresponding building in the buildingsMap
        const building = buildingsMap[locationId];
        // If the building exists, push the shipment data into its shipments array
        if (building) {
          building.shipments.push(shipmentData);
        }
      });
    } else {
      // If locationIds are undefined, check if there's a building with the key 'undefined'
      const building = buildingsMap[UNKNOWN_LOCATION_NAME];
      // If the 'undefined' building exists, push the shipment data into its shipments array
      if (building) {
        building.shipments.push(shipmentData);
      } else {
        // If the 'undefined' building doesn't exist, create it with the shipment data and an Error object as coordinates
        buildingsMap[UNKNOWN_LOCATION_NAME] = {
          name: UNKNOWN_LOCATION_NAME,
          shipments: [shipmentData],
          link: 'https://portal.pwiworks.com/shippinglog/ready-to-ship2',
          coordinates: null,
          distanceFromPWI: 'n/a'
        };
      }
    }
  });

  // Filter out buildings that do not have any shipments
  return Object.values(buildingsMap).filter(building => building.shipments.length > 0);
}

let locations: ILocation[] = [];

/**
 * Fetches and combines data from various sources.
 *
 * This function performs the following steps:
 * 1. Fetches shipping request data by calling the `fetchShippingRequests` function.
 * 2. Fetches building data by calling the `fetchBuildings` function.
 * 3. Fetches brand data by calling the `fetchBrands` function.
 * 4. Fetches traveler data by calling the `fetchTravelers` function, passing in the previously fetched building and brand data.
 * 5. Combines the fetched shipping request data and traveler data into a single array.
 *
 * @returns {Promise<Array>} A Promise that resolves to an array containing the combined data.
 */
export async function refreshData(): Promise<void> {
  if (!projectsBase || !shippingBase) {
    await initializeData();
  }

  const shippingLocations = await fetchShippingRequests();

  const contactsMap = await fetchContacts();
  const buildingsMap = await fetchBuildings(contactsMap);
  const brandsMap = await fetchBrands();
  const travelerLocations = await fetchTravelers(buildingsMap, brandsMap);

  locations = [...travelerLocations, ...shippingLocations];

  //enrich locations with earliest due date
  locations.forEach((location) => {
    if (location.shipments.length === 0) {
      location.earliestDueDate = undefined;
    }

    const earliest = location.shipments.reduce((prev, curr) =>
        curr.dueDate && (!prev || curr.dueDate < prev)
          ? curr.dueDate
          : prev,
      location.shipments[0]?.dueDate);

    location.earliestDueDate = earliest || undefined;
  })
}

/**
 * This function filters the locations based on the provided filters.
 *
 * @param {IDataFilters | undefined} filters - An optional IDataFilters object containing the filters to apply.
 *
 * @returns {ILocation[]} An array of ILocation objects that match the provided filters.
 */
export function getLocations(filters?: IDataFilters): ILocation[] {
  let filteredLocations = locations;

  // filter by sales channel
  if (filters) {
    filteredLocations = filteredLocations.filter(location =>
      location.shipments.some(shipment => filters.salesChannels?.includes(shipment.salesChannel))
    );
  }

  switch (filters?.showTypes) {
    case "ship only":
      filteredLocations = filteredLocations.filter(location => location.shipments.some(shipment => !shipment.installedByPWI));
      break;
    case "installed":
      filteredLocations = filteredLocations.filter(location => location.shipments.some(shipment => shipment.installedByPWI));
      break;
    default:
      //do nothing
      break;
  }

  // filter by production status
  if (filters?.hideNotToProduction) {
    filteredLocations = filteredLocations.filter(location =>
      location.shipments.some(shipment => shipment.toProduction)
    );
  }

  // filter by due date (on or after)
  if (filters?.onOrAfter) {
    const onAfterDateString = filters.onOrAfter.toISOString().substring(0, 10);
    filteredLocations = filteredLocations.filter(location =>
      location.shipments.some(shipment => shipment.dueDate !== undefined && shipment.dueDate >= onAfterDateString)
    );
  }

  // filter by date (on or before)
  if (filters?.onOrBefore) {
    const onBeforeDateString = filters.onOrBefore.toISOString().substring(0, 10);
    filteredLocations = filteredLocations.filter(location =>
      location.shipments.some(shipment => shipment.dueDate !== undefined && shipment.dueDate <= onBeforeDateString)
    );
  }

  return filteredLocations;
}




